<template>
    <v-col
        class="cart-list-item"
    >
        <!-- Sub Template -->
        <CartThumbnailTemplate.define #default="{ item }">
            <v-badge
                :model-value="showBadge"
                color="primary"
                offset-y="24"
                offset-x="24"
                overlap
            >
                <template #badge>
                    <strong>{{ item.qty }}</strong>
                </template>
                <v-avatar
                    class="ma-3"
                    :size="imgSize"
                    rounded="0"
                >
                    <CartThumbnailImage
                        :images="item?.bundle?.image ?? item.sku?.images"
                        :width="imgSize"
                        :height="imgSize"
                    ></CartThumbnailImage>
                </v-avatar>
            </v-badge>
        </CartThumbnailTemplate.define>

        <v-row>
            <v-col
                class="
                    flex-shrink-1
                    flex-grow-0
                "
            >
                <div
                    :class="{
                        'pl-2': inMenu
                    }"
                >
                    <NuxtLink
                        v-if="!disableLink"
                        :to="toLink"
                        class="cart-list-item-link"
                    >
                        <CartThumbnailTemplate.reuse
                            :item="item"
                        />
                    </NuxtLink>
                    <div
                        v-else
                    >
                        <CartThumbnailTemplate.reuse
                            :item="item"
                        />
                    </div>
                </div>
            </v-col>
            <v-col
                class="
                    flex-shrink-0
                    flex-grow-1
                    flex-md-row
                    flex-md-shrink-0
                    flex-md-grow-1
                    flex-lg-shrink-0
                    flex-lg-grow-1
                "
            >

                <!-- Title -->
                <div
                    class="
                        d-flex
                        flex-column
                        font-size-6
                        font-weight-medium
                        justify-center
                        line-height-1
                        fit-width
                    "
                >
                    <NuxtLink
                        v-if="!disableLink"
                        :to="toLink"
                        class="no-style-link"
                    >
                        {{ title }}
                    </NuxtLink>
                    <div
                        v-else
                    >
                        {{ title }}
                    </div>
                </div>

                <!-- Extra Info -->
                <div>
                    <!-- Gift Card Personalization Details -->
                    <template
                        v-if="item.sku?.isDigital && item.sku.isGiftCard"
                    >
                        <CartPersonalizationItem
                            :cart-line="item"
                            :size="chipSize"
                        ></CartPersonalizationItem>
                    </template>

                    <!-- Body Details -->
                    <template
                        v-if="item.bodyResponse"
                    >
                        <CartPersonBodyItem
                            :body="item.bodyResponse"
                            :size="chipSize"
                        ></CartPersonBodyItem>
                    </template>
                    <template
                        v-else-if="item.garmentSizeUid"
                    >
                        <CartGarmentSizeItem
                            :garment-size="item.garmentSize"
                            :size="chipSize"
                        ></CartGarmentSizeItem>
                    </template>

                    <!-- Attributes -->
                    <div
                        v-if="item.sku?.detailWithColor"
                    >
                        {{ item.sku?.detailWithColor }}
                    </div>

                    <!-- Bundles -->
                    <CartItemBundleList
                        v-if="item.bundle"
                        class="pt-2"
                        :bundle="item.bundle"
                    ></CartItemBundleList>

                    <!-- Backorder / Est Ship -->
                    <div
                        v-if="item.status"
                        class="d-flex flex-row align-center mt-2"
                    >
                        <ProductAvailabilityStatus
                            v-if="!!item.sku?.status"
                            :status="item.sku.status"
                            :size="chipSize"
                            label
                        ></ProductAvailabilityStatus>
                        <span
                            v-if="item.availableDate"
                            class="ml-2 font-size-7 text-no-wrap line-height-1 text-medium-emphasis"
                        >
                            estimated {{ item.availableDate }}
                        </span>
                    </div>

                    <!-- Adjustments -->
                    <CartItemAdjustmentList
                        v-if="item.adjustments?.length"
                        :adjustments="item.adjustments"
                    ></CartItemAdjustmentList>

                    <!-- Fees -->
                    <CartItemAdjustmentsDetails
                        v-if="item.fees?.length"
                        :adjustments="item.fees"
                        title="Fees"
                        class="mt-2"
                    ></CartItemAdjustmentsDetails>
                    
                    <!-- Discounts -->
                    <CartItemAdjustmentsDetails
                        v-if="item.discounts?.length"
                        :adjustments="item.discounts"
                        title="Discounts"
                        class="mt-2"
                    ></CartItemAdjustmentsDetails>
                </div>
            </v-col>
        </v-row>

        <v-row
            class="mt-0"
            dense
        >
            <v-col
                v-if="!readonly"
                class="d-flex flex-column justify-end"
            >
                <!-- Qty & Delete -->
                <div
                    class="
                        d-flex
                        flex-row
                        align-center
                        justify-start
                    "
                    :class="{ 'ml-5': !inMenu }"
                >
                    <QtyInput
                        :model-value="item.qty"
                        class="d-inline-block"
                        :disabled="cartStatus === 'working'"
                        @change="onChangeQty($event)"
                    ></QtyInput>
                    <div>
                        <v-btn
                            color="secondary"
                            variant="plain"
                            size="large"
                            @click="deleteLine(item.uid)"
                            :disabled="cartStatus === 'working'"
                            icon
                        >
                            <v-icon
                                class="mr-1"
                                :icon="mdiDelete"
                            ></v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-col>
            <v-col
                class="d-flex flex-row align-center justify-end"
            >
                <!-- Total -->
                <div
                    class="
                        d-flex
                        gap-2
                        text-no-wrap
                        px-2
                        font-size-6
                        font-weight-medium
                        text-end
                    "
                >
                    <span
                        v-if="isDiscount"
                        class="text-decoration-line-through text-medium-emphasis"
                    >
                        {{ formatCurrency(item.subtotal) }}
                    </span>
                    <span>
                        {{ formatCurrency(item.total) }}
                    </span>
                </div>
            </v-col>
        </v-row>

        <!-- Divider / Spacer -->
        <v-row
            dense
        >
            <v-col
                v-if="showDivider"
            >
                <v-divider></v-divider>
            </v-col>
            <v-col
                v-else
            >
                <div class="mb-4"></div>
            </v-col>
        </v-row>

    </v-col>
</template>

<script lang="ts" setup>
    import type {
        Sku,
        CartLine,
        Cart
    } from '~/types';
    import { formatCurrency, pathJoin } from '~/utils';
    import { debounce as lo_debounce } from 'es-toolkit';
    import { mdiDelete } from '@mdi/js';
    import { createReusableTemplate } from '@vueuse/core';

    import QtyInput from '~/components/controls/QtyInput/QtyInput.vue';
    import CartThumbnailImage from '~/components/cart/CartThumbnailImage/CartThumbnailImage.vue';
    import CartItemAdjustmentList from '~/components/cart/CartItemAdjustmentList/CartItemAdjustmentList.vue';
    import CartItemAdjustmentsDetails from '~/components/cart/CartItemAdjustmentsDetails/CartItemAdjustmentsDetails.vue';
    import CartItemBundleList from '~/components/cart/CartItemBundleList/CartItemBundleList.vue';
    import CartPersonBodyItem from '~/components/cart/CartPersonBodyItem/CartPersonBodyItem.vue';
    import CartGarmentSizeItem from '~/components/cart/CartGarmentSizeItem/CartGarmentSizeItem.vue';
    import CartPersonalizationItem from '~/components/cart/CartPersonalizationItem/CartPersonalizationItem.vue';
    import ProductAvailabilityStatus from '~/components/products/ProductAvailabilityStatus/ProductAvailabilityStatus.vue';

    const props = withDefaults(
        defineProps<{
            item: CartLine;
            cartObj: Cart;
            cartStatus: string;
            showDivider?: boolean;
            disableLink?: boolean;
            inMenu?: boolean;
            readonly?: boolean;
            showBadge?: boolean;
        }>(),
        {
            showDivider: true
        }
    );

    const emit = defineEmits<{
        'change-qty': [value: number];
        'delete-line': [value: string];
    }>();

    const CartThumbnailTemplate = createReusableTemplate<{ item: CartLine }>();
    const imgSize = computed<number>(() => props.inMenu ? 64 : 124);
    const chipSize = computed<string>(() => props.inMenu ? 'x-small' : 'small');

    const onChangeQty = lo_debounce((qty: number) => {
        changeQty(qty);
    }, 800);

    const isDiscount = computed(() => {
        return props.item.subtotal > props.item.total;
    });

    const toLink = computed<string>(() => props.item?.sku ? pathJoin('/', props.item.sku?.path ?? '') : '');
    const title = computed<string>(() => props.item.sku?.productName ?? props.item.sku?.name ?? '');

    function changeQty(qty: number = 0) {
        emit('change-qty', qty);
    }

    function deleteLine(uid: string | undefined) {
        if (uid) {
            emit('delete-line', uid);
        }
    }
</script>

<style lang="scss"></style>
