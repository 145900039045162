<template>
    <v-chip
        v-if="statusText"
        :color="useGetStatusColor(statusText)"
        class="product-availability-status text-uppercase"
    >
        {{ statusText }}
    </v-chip>
</template>

<script lang="ts" setup>
    import type {
        ProductAvailabilityStatus
    } from '~/types';
    import {
        useGetStatusColor,
        useProductAvailabilityStatus
    } from '~/composables';

    const props = defineProps<{
        status: string;
    }>();

    const {
        statusText
    } = useProductAvailabilityStatus(props.status as ProductAvailabilityStatus);
</script>

<style lang="scss">
.product-availability-status {
    .v-chip__content {
        color: rgb(var(--v-theme-on-surface));
    }
}
</style>
